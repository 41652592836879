import React from "react";
import { Link } from "gatsby-plugin-react-i18next";

const HeaderHome = ({ headerContent }) => {
  return (
    <header className="m-headerPrimary">
      <div
        className="_wr m-headerPrimary__wrapper"
        // style={{ backgroundImage: `url("${headerContent.imageUrl}")` }}
      >
        <div className="_w m-headerPrimary__content">
          <div className="_xs10 _l6 m-headerPrimary__textContent">
            <h1 className="m-headerPrimary__textContent--title">
              {headerContent.title}
            </h1>
            <p className="m-headerPrimary__textContent--text">
              {headerContent.text}
            </p>
            <Link to={headerContent.buttonUrl} className="a-btn -primary">
              {headerContent.buttonText}
            </Link>
          </div>
          <div
            className="m-headerMarkets__bkg -global _m5 _xl6"
            style={{ backgroundImage: `url("${headerContent.imageUrl}")` }}
          ></div>
        </div>
      </div>
    </header>
  );
};

export default HeaderHome;
