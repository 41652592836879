import * as React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import HeaderPrimary from "../components/headers/header-primary";
import NumberedCards from "../components/homepage/numbered-cards";
import Layout from "../components/layout/layout";
import Seo from "../components/seo/seo";
import BgImage from "../assets/images/headers/careers.png";
import { graphql } from "gatsby";
import TextTwoCols from "../components/global/text-two-cols";
import BgDivider from "../components/global/bg-divider";
import Perks from "../components/careers/perks";
import OpenPositions from "../components/careers/open-positions";

const Carrers = () => {
  const { t } = useTranslation();
  const headerContent = {
    title: `${t("careers.header.title")}`,
    text: `${t("careers.header.text")}`,
    buttonText: `${t("buttons.seeJobs")}`,
    buttonUrl: `/careers/#jobs`,
    imageUrl: BgImage,
  };

  const textTwoColContent = {
    title: `${t("aboutUs.about.headlineSecond")}`,
    textLeftFirst: `${t("aboutUs.about.firstText")}`,
    textLeftSecond: `${t("aboutUs.about.secondText")}`,
    textRightFirst: `${t("aboutUs.about.fourthText")}`,
    textRightSecond: `${t("aboutUs.about.fifthText")}`,
  };

  const numberedCardsContent = {
    title: `${t("careers.numberedCards.headline")}`,
    text: `${t("careers.numberedCards.text")}`,
    cards: [
      {
        title: `${t("careers.numberedCards.cards.first.title")}`,
        text: `${t("careers.numberedCards.cards.first.text")}`,
      },
      {
        title: `${t("careers.numberedCards.cards.second.title")}`,
        text: `${t("careers.numberedCards.cards.second.text")}`,
      },
      {
        title: `${t("careers.numberedCards.cards.third.title")}`,
        text: `${t("careers.numberedCards.cards.third.text")}`,
      },
      {
        title: `${t("careers.numberedCards.cards.fourth.title")}`,
        text: `${t("careers.numberedCards.cards.fourth.text")}`,
      },
    ],
  };

  return (
    <Layout>
      <Seo title="Careers" />
      <HeaderPrimary headerContent={headerContent} />
      {/* <BgDivider /> */}
      <TextTwoCols textTwoColContent={textTwoColContent} />
      <OpenPositions />
      <Perks cssModifer="-carrers" />
      <NumberedCards
        numberedCardsContent={numberedCardsContent}
        cssModifer="-carrers"
      />
    </Layout>
  );
};

export default Carrers;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
